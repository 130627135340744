import { PropsWithChildren, createContext, useContext } from 'react';
import { createContextualCan } from '@casl/react';
import { AppAbility } from '@hub/authorization';
import { useAuth } from '@hub/cognito-auth';
import { buildAbilityFor } from './ability';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AbilityContext = createContext<AppAbility>(undefined!);

export const AbilityProvider = ({ children }: PropsWithChildren) => {
  const { currentUserAbilities } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ability = buildAbilityFor(currentUserAbilities!);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

/**
 * Usage:
 *
 * ```typescript
 * const ability = useAbility();
 * console.log(ability.can(UserAction.READ, 'User'));
 * ```
 */
export const useAbility = () => {
  const context = useContext(AbilityContext);

  if (context === undefined) {
    throw new Error('useAbility must be used within a AbilityProvider');
  }

  return context;
};

export const Can = createContextualCan(AbilityContext.Consumer);

export default Can;
