export const routes = {
  home: '/',
  login: '/customauth/customsignin',
  signUp: '/customauth/customsignup',
  forgotPassword: '/customauth/forgotpassword',
  changePassword: '/users/change-password',
  privacyPolicy: '/privacy-policy',
  users: '/users',
  userProfile: '/users/me',
  emails: '/emails',
  tenantSettings: '/settings/organization',
  externalLinks: '/external-links',
  slackManagment: '/slack',
  slackBotHistory: '/slack/ai-assistant/history',
  support: '/support',
  clients: '/clients',
  projects: '/projects',
  integrations: '/integrations',
  timeReports: '/time-reports',
  allTimeReports: '/all-time-reports',
  payments: '/payments',
  statistics: '/statistics',
  publicHolidays: '/public-holidays',
  formsManagement: '/settings/forms-management',
  filteredAllTimeReports: (args: {
    dateRange: { firstDay: Date; lastDay: Date };
    employeeId: string;
    projectId: string;
  }) => {
    return `${routes.allTimeReports}?selectedEmployee=${encodeURIComponent(
      args.employeeId,
    )}&dateRange=${JSON.stringify(args.dateRange)}&selectedProject=${encodeURIComponent(
      args.projectId,
    )}`;
  },
  approvals: '/approvals',
  approvalPeriodDetails: (id: string) => `/approvals/${id}`,
  settings: {
    organization: '/settings/organization/',
    timeLogs: '/settings/time-logs',
  },
};
